body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

form {
  margin: auto;
}

img {
  border-radius: 8px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5); 
}

@font-face {
  font-family: titleFont;
  src: url("/src/Tangerine-Regular.ttf");
}

@font-face {
  font-family: oswald;
  src: url("/src/Oswald.ttf");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.widget {
  cursor: pointer;
  transform: scale(1);
  transition: all .5s ease-in-out;
}

.widget:hover {
  transform: scale(1.1);
  transition: all .5s ease-in-out;
}

.drawer-item {
  cursor: pointer;
  transform: scale(1);
  transition: all .5s ease-in-out;
}

.drawer-item:hover {
  transform: scale(1.1) translateX(10px);
  transition: all .5s ease-in-out;
  color: #fff;
}

.cart-button {
  animation: wiggle 2s linear infinite;
  -webkit-animation: wiggle 2s linear infinite;
  -moz-animation: wiggle 2s linear infinite;
  cursor: pointer;
}

/* Keyframes */
@keyframes wiggle {
  0%, 7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%, 100% {
    transform: rotateZ(0);
  }
}
